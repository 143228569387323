// only use transparent header if a title block used as first page element
header
  background-color: $bg-navbar
.has-title-block header
  background-color: transparent

header
  position: fixed
  top: 0
  left: 0
  z-index: 1000

  width: 100%
  height: auto

  padding-top: 1rem
  padding-bottom: 1rem

  transition: .75s ease-in-out
  img
    transition: .75s ease-in-out
    max-height: 6rem

  .visible-top
    visibility: visible
    opacity: 1

  .visible-scrolled
    visibility: hidden
    opacity: 0

  &.is-scrolled
    background-color: $bg-navbar
    padding-top: .5rem
    padding-bottom: .5rem

    .navbar-brand
      height: 2rem

    img
      max-height: 2rem

    .visible-top
      visibility: hidden
      opacity: 0

    .visible-scrolled
      visibility: visible
      opacity: 1

  color: $color-navbar

  .navbar-brand
    position: relative
    overflow: visible
    height: $navbar-brand-image-height
    transition: .75s ease-in-out

    img
      position: absolute
      top: 0
      left: 0

      height: $navbar-brand-image-height
      width: auto
      max-width: none

  nav
    a
      font-weight: bold

  .navbar-collapse.collapse.show, .navbar-collapse.collapsing
    background-color: $bg-navbar

    margin-top: 1rem
    padding: 2rem
    .nav-item
      margin: 1rem 0

  .navbar-nav
    margin-left: auto

    .nav-item
      margin-left: 1.15rem
      margin-right: 1.15rem
      &:last-child
        margin-right: 0

      white-space: nowrap

    .nav-link
      padding: 0
