main
  background-color: $content-bg-primary

.block
  padding-top: $block-padding-y
  padding-bottom: $block-padding-y

.intro
  color: $primary
  font-size: 1.375rem // 22
  line-height: 1.275 // 28

.alert
  background: none !important

// add some padding if the first page element is not a title block
main
  padding-top: 1.5 * $navbar-brand-image-height
.has-title-block main
  padding-top: 0

main
  ul
    padding: 0
    margin: 0 0 2.25rem 0 // 40
    list-style: none

  ul li
    margin-top: .625rem // 10
    padding-left: 2.5rem // 40

  ul li::before
    display: inline-block
    width: 2.5rem // 40
    margin-left: -2.5rem // 40

    content: "►"

    color: $primary


// Title

.title-block
  background-position: top
  background-size: cover
  background-color: $footer-bg
  color: $footer-color

  position: relative
  padding: 13.75rem 0 // ~ header height + 120

  &.color-light
    background-color: $secondary
    color: $white

  &.color-dark
    background-color: $white
    color: $body-color

  .inner
    @extend .container

  h1
    text-align: center
    @include media-breakpoint-down('sm')
      font-size: $h2-font-size

  h2
    font-size: 1.5rem
    font-weight: 700
    text-align: center

    padding-top: 2rem

  .cta
    text-align: center

    padding-top: 3rem

    a
      @extend .btn
      @extend .btn-primary

  .badges
    position: absolute
    bottom: 0
    right: 0

    padding: 0.5rem

    .badge
      padding: 0.5rem


// Listing

.listing-block
  .inner
    @extend .container

    display: flex
    flex-direction: row
    justify-content: center
    > *
      flex-basis: 0
      flex-grow: 1
      flex-shrink: 1

      display: flex
      justify-content: stretch
      align-items: center

  .image
    @include media-breakpoint-down('md')
      display: none

  .content
    padding-left: 7.5rem // 120
    @include media-breakpoint-down('md')
      padding-left: $grid-gutter-width/2

  .label
    font-size: $h4-font-size
    font-weight: $font-weight-bold

  .text
    font-size: 1rem
    display: block

  ol
    padding: 0
    margin: 0

    list-style: none
    counter-reset: listing-counter

  ol li
    margin-top: 2rem
    padding-left: 4rem
    counter-increment: listing-counter

  ol li::before
    display: inline-block
    width: 4rem
    margin-left: -4rem

    content: counter(listing-counter) "."

    color: $primary
    font-size: 2rem
    font-weight: bold


// Portfolio

.portfolio-block
  background-color: $content-bg-secondary

  .inner
    @extend .container

  .content
    @extend .row

  h2
    text-align: center

    margin: 0 auto

  h3
    text-align: center

  .list
    @extend .row
    @include media-breakpoint-down('sm')
      flex-direction: column

  .item
    @extend .col

    margin-top: 2rem
    text-align: center
    p
      text-align: left

  .icon
    display: inline-block
    width: 10rem
    height: 10rem

    font-size: 5rem
    line-height: 10rem
    text-align: center

    margin: 0 auto 1rem auto

    border-color: $content-bg-secondary
    border-style: solid
    border-radius: 8rem

    background-color: $content-bg-primary
    color: $primary

  .more-link
    margin-top: 1rem
    a
      text-decoration: underline


// Seminars

.seminar-list
  background-color: $content-bg-primary

  .inner
    @extend .container

  .list
    @extend .row

  .item
    flex: 1 0 90%
    @include media-breakpoint-up('sm')
      flex: 1 0 40%
    @include media-breakpoint-up('lg')
      flex: 1 0 30%

    margin: 2.5rem $grid-gutter-width/2 0 $grid-gutter-width/2 // 40
    padding: 0

    background-color: $content-bg-secondary

    display: flex
    flex-direction: column
    justify-content: space-between
    > *
      flex-basis: 0
      flex-shrink: 0
      flex-grow: 0
      &.teaser
        flex-grow: 1

      margin: 0 1.25rem 1.25rem 1.25rem // 20
      &.image
        margin: 0 0 1.25rem 0 // 20
        padding: 0

  h2
    margin-bottom: 0
    text-align: center


// Person

// fix top margin on about me page
.block-title + .block-person > .person-block
  margin-top: 0

.person-block
  background-color: $content-bg-secondary
  padding-bottom: 0

  .inner
    @extend .container
    @include media-breakpoint-down('md')
      margin-right: 2rem
    @include media-breakpoint-down('sm')
      margin: 0
      padding: 0 2rem
      max-width: 100%

  .content
    @extend .row

  .text
    @extend .col-12
    @extend .col-sm-7
    @extend .col-md-6
    align-self: end
    @include media-breakpoint-down('xs')
      order: 1

  .picture
    @extend .col-12
    @extend .col-sm-5
    @extend .col-md-6
    align-self: end
    padding-bottom: 0
    @include media-breakpoint-down('xs')
      order: 0

  h2
    text-align: center

  h3
    text-align: left

  .cta
    padding-top: 1.25rem // 40 - [p margin bottom]
    padding-bottom: 3.75rem // 60

    a
      @extend .btn
      @extend .btn-primary


// Blog Article

.article-list
  background-color: $content-bg-primary

  .inner
    @extend .container

  .list
    @extend .row

  .article
    @extend .col
    overflow: hidden
    flex-shrink: 1
    flex-basis: 90%
    max-width: 100%
    @include media-breakpoint-up('sm')
      flex-basis: 40%
      max-width: 50%
    @include media-breakpoint-up('lg')
      flex-basis: 21%
      max-width: 25%

    margin: 2.5rem $grid-gutter-width/2 0 $grid-gutter-width/2 // 40
    padding: 0

    > *
      margin: 0 0 1.25rem 0 // 20

    > .title
      hyphens: auto
      overflow-wrap: break-word

  h2
    text-align: center
    margin: 0
    padding: 0

  .more-link
    margin-top: 2.25rem // 40
    text-align: center

.article-details
  h1
    font-size: $h2-font-size
  h2
    font-size: $h3-font-size

.article-tags
  margin-bottom: 0.5rem

  .badge
    background-color: $gray-700
    font-weight: normal
    padding-left: 0.7em
    padding-right: 0.7em
    line-height: 1.2

// Testimonials

.testimonial-slider
  background-color: $content-bg-secondary
  padding-top: $block-padding-y
  padding-bottom: $block-padding-y

  text-align: center

  .inner
    @extend .container

  .carousel-inner
    width: 80%
    margin: 0 auto

  .carousel-control-prev, .carousel-control-next
    font-size: 3rem
    color: $gray-500

  h2
    text-align: center

    margin: 0 auto 3rem 0

  .carousel-item

    .image
      width: 10rem
      height: 10rem
      margin: 0 auto 3rem auto

    img
      border-radius: 50% !important

    .quote
      margin: 0 6rem 3rem 6rem
      @include media-breakpoint-down('md')
        margin-right: 3rem
        margin-left: 3rem
      @include media-breakpoint-down('sm')
        margin-right: 1.5rem
        margin-left: 1.5rem


// Text Blocks

.text-side-headline-block
  .inner
    @extend .container

  .headline
    @extend .col-12
    @extend .col-md-4

  .content
    @extend .col-12
    @extend .col-md-8
    padding-left: 7.5rem // 120
    @include media-breakpoint-down('xs')
      padding-left: $grid-gutter-width/2

  h2
    text-align: right
    @include media-breakpoint-down('sm')
      text-align: left

    padding-bottom: 1.25rem // 20
    border-bottom: .75rem solid $primary
    margin-bottom: 3.75rem // 60


.text-with-sidebar
  .inner
    @extend .container

  .sidebar
    @extend .col-12
    @extend .col-md-4

  .content
    @extend .col-12
    @extend .col-md-8

  .mobile-sidebar-top, .mobile-sidebar-bottom
    display: none
    @include media-breakpoint-down('sm')
      display: flex

  .sidebar
    @include media-breakpoint-down('sm')
      display: none

  &.sidebar-position-right
    .content
      order: 0
      padding-right: 7.5rem // 120
      @include media-breakpoint-down('sm')
        order: 0
        padding-right: $grid-gutter-width/2

    .sidebar
      order: 1

  &.sidebar-position-left
    .content
      order: 2
      padding-left: 7.5rem // 120
      @include media-breakpoint-down('sm')
        order: 0
        padding-left: $grid-gutter-width/2

    .sidebar
      order: 1

  &.no-sidebar
    .content
      padding-left: $grid-gutter-width/2
      @extend .col-md-12

  .cta
    margin-top: 2.5rem // 40
    margin-bottom: 2.5rem // 40

    a
      @extend .btn
      @extend .btn-primary


.text-centered-block
  .inner
    @extend .container
    padding: 0 15rem // 240
    @include media-breakpoint-down('md')
      padding: 0 3.75rem // 60
    @include media-breakpoint-down('xs')
      padding: 0 $grid-gutter-width/2


// Statement

.statement-block
  margin-top: $block-padding-y
  background-position: top
  background-size: cover
  background-color: $primary
  color: $white

  padding: 2.5rem // 40

  h1
    text-align: center
    margin: 0
    padding: 0
    @include media-breakpoint-down('sm')
      font-size: $h2-font-size


// Picture

main > .block-picture
  @extend .container
  margin-top: $block-padding-y
  margin-bottom: $block-padding-y

  .picture
    padding: 0

.block-picture
  padding: 0
  display: flex
  flex-direction: column
  align-items: center

  .show-border .image
    padding: $grid-gutter-width
    border: 1px solid $primary


.picture
  padding: 0 $grid-gutter-width/2 4rem $grid-gutter-width/2
  text-align: center

  .caption
    @extend .text-muted
    font-size: .75rem // 12
    line-height: 1.25 // 15
    margin-top: 1.25rem // 20
    text-align: left


// Call-to-Action

.contact-cta-block
  margin-top: 0
  background-color: $primary

  .inner
    @extend .container
    color: $white
    a
      color: $white
    padding: 0 $spacer * 3
    position: relative
    @include media-breakpoint-down('sm')
      padding: 0 $grid-gutter-width/2

  .content
    @extend .col-12
    @extend .col-md-7
    padding-top: $spacer * 3
    padding-bottom: $spacer * 3
    display: flex
    flex-direction: column
    justify-content: center
    align-items: stretch
  &.no-image .content
    @extend .col-md-12
  .image
    @extend .col-md-5
    padding-top: $spacer * 3
    padding-bottom: $spacer * 3
    align-self: center
    bottom: 0
    right: 0
    text-align: right

    @include media-breakpoint-down('sm')
      display: none

  .text
    font-size: 1.125rem
    line-height: 1.25

  .cta
    margin-top: $spacer * 3

  &.image-overflow
    .image
      padding-bottom: 0
      align-self: end


// Seminars

.seminar-details-page, .seminar-register-page
  .title-block
    h2
      line-height: 4
      span
        margin: 0 5rem // 80
        white-space: nowrap
      i
        margin-right: 1.25rem // 20

  .description, .target-group, .benefits, .schedule, .speaker
    margin-bottom: 3.75rem // 60

  .seminar .benefits
    ul li::before
      content: "✓"

  .seminar .registration-notice
    margin-top: 3.75rem // 60

  .speaker
    .name-and-image
      background-color: $primary
      color: $white
      padding: 1.25rem 0 0 1.25rem //20

    .speaker-description
      @extend .text-muted
      font-size: .75rem // 12
      line-height: 1.25 // 15
      margin-top: 1.25rem // 20

  .next-events, .help-box
    padding: 1.25rem 2.5rem // 20 / 40
    @include media-breakpoint-down('sm')
      padding: $grid-gutter-width $grid-gutter-width/2

    border: 1px solid $primary

    h4
      color: $primary

    ul li::before
      content: "►"

  .seminar
    h2
      width: 50%
      @include media-breakpoint-down('sm')
        width: 100%
      padding-bottom: 1.25rem // 20
      border-bottom: .75rem solid $primary
      margin-bottom: 2.5rem // 40
    .speaker h2
      width: 100%

    .seminar-summary
      margin-bottom: 3.75rem // 60
      dl
        color: $primary
        display: grid
        grid-template-columns: 3.75rem 1fr // 60
        grid-row-gap: 1.25rem // 20
      dt, dd
        margin: 0
        padding: 0

    ul
      margin: 0 0 3.75rem 0 // 60

    ul li
      margin-top: 1.25rem // 20

    .cta
      margin-top: 2.5rem // 40
      margin-bottom: 2.5rem // 40

      a
        @extend .btn
        @extend .btn-primary

.seminar-register-page
  .title-block
    padding: 7.5rem 0 0 0 // 120
  .seminar
    padding: 7.5rem 0 // 120
  form
    :not([type="submit"]):valid
      color: $green
      box-shadow: inherit
    *:not([type="submit"]):not(select):invalid
      color: $red
      border-color: $input-border-color
      box-shadow: inherit
    *[type="submit"]
      margin: 2.5rem 0 // 40


// Publications

.publication-list
  .inner
    @include make-non-fluid-container()

  .publication
    margin-bottom: 3rem


// Gallery

.gallery-block
  .inner
    @include make-non-fluid-container()
    padding-bottom: 2rem

  .gallery
    @include make-row()
    .item
      @include make-col-ready()
      @include make-col(4)
      margin-bottom: 2rem
      text-align: center

      .image
        margin-bottom: 1rem
