@font-face
  font-family: 'NunitoSans'
  font-weight: $font-weight-normal
  font-style: normal
  font-display: block
  src: url('../fonts/NunitoSans-Regular.ttf') format('truetype')

@font-face
  font-family: 'NunitoSans'
  font-weight: $font-weight-bold
  font-style: normal
  font-display: block
  src: url('../fonts/NunitoSans-Bold.ttf') format('truetype')

@font-face
  font-family: 'NunitoSans'
  font-weight: $font-weight-bold
  font-style: italic
  font-display: block
  src: url('../fonts/NunitoSans-BoldItalic.ttf') format('truetype')

@font-face
  font-family: 'Montserrat'
  font-weight: $font-weight-normal
  font-style: normal
  font-display: block
  src: url('../fonts/Montserrat-Regular.ttf') format('truetype')

@font-face
  font-family: 'Montserrat'
  font-weight: $font-weight-bold
  font-style: normal
  font-display: block
  src: url('../fonts/Montserrat-Bold.ttf') format('truetype')

@font-face
  font-family: 'Montserrat'
  font-weight: $font-weight-bold
  font-style: italic
  font-display: block
  src: url('../fonts/Montserrat-BoldItalic.ttf') format('truetype')

@font-face
  font-family: 'Montserrat'
  font-weight: $font-weight-normal
  font-style: italic
  font-display: block
  src: url('../fonts/Montserrat-Italic.ttf') format('truetype')
