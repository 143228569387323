
footer
  padding-top: 2rem
  padding-bottom: 2rem

  background-color: $footer-bg
  color: $footer-color

  font-size: 1.2rem

  a
    color: inherit
    text-decoration: none

    &:hover, &:focus, &:active
      color: inherit
      text-decoration: underline

  .logo, .contact, .social
    margin-bottom: 2rem

  .logo
    img
      width: 100%
      @include media-breakpoint-up('sm')
        width: 80%
      @include media-breakpoint-up('lg')
        width: 60%

  .contact
    display: inline-grid
    grid-column: 2
    column-gap: 2rem
    row-gap: 0
    grid-template-columns: min-content auto
    justify-content: stretch

  .social
    font-size: 43px
    line-height: 43px * 1.25
    letter-spacing: 1rem

  nav
    a
      font-weight: bold
    .children a
      font-size: 1rem
      font-weight: normal

    .col-12, .col-sm-6, .col-lg-4, .col-xl-3
      margin-bottom: 1rem
