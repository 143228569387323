@import "~bootstrap/scss/functions"


// Color system

$white: #fff
$gray-100: #f8f9fa
$gray-200: #e9ecef
$gray-300: #dee2e6
$gray-400: #ced4da
$gray-500: #adb5bd
$gray-600: #6c757d
$gray-700: #495057
$gray-800: #343a40
$gray-900: #212529
$black: #000

$brand-blue: #3770c0
$brand-green: #89d57d
$brand-red: #7c223c
$brand-aubergine: #261827

$primary: $brand-green
$secondary: $brand-aubergine

$green: $brand-green

$bg-navbar: $secondary
$color-navbar: $white

$footer-bg: $secondary
$footer-color: $white

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light".
// Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 190


// Font Awesome

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts"


// Spacing
$block-padding-y: 7.5rem // 120


// Body
//
// Settings for the `<body>` element.

$body-bg: $footer-bg

$content-bg-primary: $white
$content-bg-secondary: #e9e7e9


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 20px


// Components
//
// Define common padding and border radius sizes and more.
$border-radius: 0


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1.25rem // 20


// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

$headings-font-family: NunitoSans
$headings-font-weight: 700

$font-size-base: 1.125rem // = 18px; assumes browser default: 16px
$line-height-base: 1.55 // 28

$h1-font-size: 72px
$h2-font-size: 48px
$h3-font-size: 36px
$h4-font-size: 30px
$h5-font-size: 24px
$h6-font-size: 20px

$headings-margin-bottom: 1.25rem // 20

$text-muted: #26182780


// Navbar

$navbar-dark-color: $gray-200
$navbar-dark-hover-color: $white
$navbar-dark-active-color: $white

$navbar-padding-y: 0
$navbar-padding-x: 0

$navbar-nav-link-padding-x: 0

$navbar-brand-image-height: 64px


// Buttons

$btn-font-size: $h5-font-size
$btn-border-radius: 1.875rem // 30

$btn-padding-y: .6875rem // 60 - 2 // 58 - 36 // 22 / 2 // 11
$btn-padding-x: 3.125rem // 50


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-border-level: 0
$alert-color-level: 0


// Bootstrap Defaults

@import "~bootstrap/scss/variables"
